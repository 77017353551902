import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTopic,
  videosDetailsSelector,
} from "../../../../store/reducers/videoDetails/index.reducer";

const TopicDetails = () => {
  const {
    insights: { topics },
    defaultInsight: { currentTopic },
  } = useSelector(videosDetailsSelector);
  const dispatch = useDispatch();

  const handleMoveToSpecificTime = (event) => {
    const newBrand = topics.find((brand) => brand.name === event.target.value);
    dispatch(changeTopic(newBrand));
  };

  return (
    <>
      <div className="mt-4">
        <select
          className="px-2 py-1 border"
          value={currentTopic.name}
          onChange={handleMoveToSpecificTime}
        >
          {topics?.map((brand) => (
            <option
              key={brand.id}
              value={brand.name}
            >
              {brand.name} ({brand.instances.length})
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default TopicDetails;
