import { toast } from "react-toastify";
import { useSelector } from "react-redux";
//import { HiOutlinePlayCircle } from "react-icons/hi2";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import constants from "../../../../../utils/constants";
import { getVideoAccessToken } from "../../../../../api/helpers";
import { getVideoPlayerURL } from "../../../../../utils/helpers";
import { videosDetailsSelector } from "../../../../../store/reducers/videoDetails/index.reducer";

const VideoPlayer = () => {
  const [videoAccessToken, setVideoAccessToken] = useState();

  const {
    currentStartTime,
    videoDetails: { id },
  } = useSelector(videosDetailsSelector);

  const videoURL = useMemo(
    () => getVideoPlayerURL(id, videoAccessToken),
    [videoAccessToken, id]
  );

  const moveToSpecificTime = useCallback(() => {
    try {
      if (currentStartTime) {
        const videoPlayerFrame = document.getElementById(
          constants.VIDEO_PLAYER_ID
        );

        const payload = {
          time: currentStartTime,
          origin: "https://www.videoindexer.ai",
        };
        if ("postMessage" in window) {
          videoPlayerFrame.contentWindow.postMessage(payload, payload.origin);
        }
      }
    } catch (error) {
      toast.error(constants.ERROR_MESSAGE.VIDEO_NEXT_MOVE);
    }
  }, [currentStartTime]);

  useEffect(() => moveToSpecificTime(), [moveToSpecificTime]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getVideoAccessToken(id);
        setVideoAccessToken(response);
      } catch (error) {
        toast.error(constants.ERROR_MESSAGE.VIDEO_TOKEN_AUTHENTICATION_FAILED);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-center relative">
      <iframe
        id={constants.VIDEO_PLAYER_ID}
        src={videoURL}
        allowFullScreen
        className="w-full object-contain rounded"
        title="player"
      ></iframe>
      {/*<div className="absolute">
        <HiOutlinePlayCircle className="text-gray-200 text-4xl font-light" />
      </div>*/}
    </div>
  );
};

export default memo(VideoPlayer);
