import axios from "axios";

import instance, { headerConfig } from ".";
import constants from "../utils/constants";
import { isTokenExpired } from "../utils/helpers";

const fast_api_url =
  process.env.NODE_ENV === "development"
    ? constants.FAST_API_DEV_ENDPOINT
    : constants.FAST_API_PROD_ENDPOINT;

/**
 * Check Token is expiry or not
 */
const checkTokenExpiry = async () => {
  const token = localStorage.getItem(constants.TOKEN_NAME);
  if ((token && isTokenExpired(token)) || !token) {
    await getAuthToken();
  }
};

/**
 * Generate Access Token
 */
export const getAuthToken = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await instance.get(
        `/Auth/trial/Accounts/${constants.ACCOUNT_ID}/AccessToken?allowEdit=true`,
        headerConfig
      );
      if (response?.status === 200 && response?.data) {
        localStorage.setItem(constants.TOKEN_NAME, response?.data);
        resolve(response);
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

/**
 * Get All Videos
 */
export const getAllVideos = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();

      const response = await instance.get(
        `/trial/Accounts/${constants.ACCOUNT_ID}/Videos?name=Sample&privacy=Private&priority&description&partition&externalId&externalUrl&callbackUrl&metadata&language=en-US&videoUrl=https://breakdown.blob.core.windows.net/uploads/1cbb49ff-5f09-4b79-9c74-7c3c32de79e6/tom graham metaphysic ai at ted2023 interview clip.mp4?sv=2021-10-04%26st=2023-05-15T10%3A57%3A41Z%26se=2023-05-15T13%3A02%3A41Z%26sr=b%26sp=rwl%26sig=TEQkGy2G2HPpsZ9GveIPdWGqjdfskdaq7ZsS21mtepU%3D&fileName&indexingPreset=Default&streamingPreset=SingleBitrate&sendSuccessEmail=true`
      );
      if (response?.status === 200 && response?.data?.results) {
        resolve(response.data.results);
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

/**
 *
 * Post Video to video indexer
 * @param {*} fileName
 * @param {*} url
 */
export const postVideo = async (fileName, url) => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();

      const response = await instance.post(
        `/trial/Accounts/${constants.ACCOUNT_ID}/Videos?name=${fileName}&
        privacy=Private&priority&description&partition&externalId&externalUrl&callbackUrl&metadata&
        language=en-US&videoUrl=${url}
        &fileName&indexingPreset=Default&streamingPreset=SingleBitrate&sendSuccessEmail=true`
      );
      if (response?.status === 200 && response?.data) {
        resolve(response.data);
      } else {
        reject({ message: constants.SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

/**
 * Get Video Details by video id
 * @param {*} videoId
 * @returns
 */
export const getVideoIndexDetails = (videoId) => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();

      const response = await instance.get(
        `/trial/Accounts/${constants.ACCOUNT_ID}/Videos/${videoId}/Index`
      );
      if (response?.status === 200 && response.data) {
        resolve(response.data);
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

/**
 * Get the video thumbnail by video id and thumbnail id
 * @param {*} videoId
 * @param {*} thumbnailId
 * @returns
 */
export const getThumbnail = async (videoId, thumbnailId) => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();

      const response = await instance.get(
        `/trial/Accounts/${constants.ACCOUNT_ID}/Videos/${videoId}/Thumbnails/${thumbnailId}?format=Base64`
      );

      if (response) {
        resolve(response);
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

/**
 * Delete video
 * @param {*} videoId
 * @returns
 */
export const deleteVideo = (videoId) => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();

      const response = await instance.delete(
        `/trial/Accounts/${constants.ACCOUNT_ID}/Videos/${videoId}`
      );
      if (response?.status === 204) {
        resolve({ status: response?.status });
      } else {
        reject({ message: constants.SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getVideoDownloadURL = (videoId) => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();

      const response = await instance.get(
        `/trial/Accounts/${constants.ACCOUNT_ID}/Videos/${videoId}/SourceFile/DownloadUrl`
      );

      if (response?.status === 200 && response?.data) {
        resolve(response?.data);
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getVideoAccessToken = (videoId) => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();

      const response = await instance.get(
        `/Auth/trial/Accounts/${constants.ACCOUNT_ID}/Videos/${videoId}/AccessToken?allowEdit=true`,
        headerConfig
      );

      if (response?.data) {
        resolve(response?.data);
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

export const uploadVideo = (imageFile) => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();

      const response = await axios.post(
        `${fast_api_url}/api/uploadfile/`,
        imageFile
      );

      if (response?.data && response.status === 200) {
        resolve(response?.data);
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getVideoDetails = (videoId) => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();

      const response = await instance.get(
        `/internals/trial/accounts/${constants.ACCOUNT_ID}/videos/${videoId}`
      );
      if (response?.data && response.status === 200) {
        resolve(response?.data);
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getVideoSrt = (videoId, format = "txt") => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();
      const url = `/trial/Accounts/${constants.ACCOUNT_ID}/Videos/${videoId}/Captions?indexId=${videoId}&format=${format}&language=en-US`;
      const response = await instance.get(url);
      if (response?.data && response.status === 200) {
        await axios.post(`${fast_api_url}/api/summary/`, {
          srt: response?.data,
          videoId: videoId,
        });
        resolve(response?.data);
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getOpenApiRes = (videoId, question) => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();
      const response = await axios.post(`${fast_api_url}/api/getAnswer/`, {
        question: question,
        videoId: videoId,
      });
      if (response?.data?.message && response.status === 200) {
        resolve(response?.data?.message);
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};


export const generateSummaryForVideo = (videoId) => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();
      const response = await axios.get(
        `${fast_api_url}/api/generateSummary/${videoId}`
      );
      if (response?.data && response.status === 200) {
        resolve(response?.data?.data || "");
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};


export const getOpenAiResV3 = (videoId, question, chatModel) => {
  return new Promise(async (resolve, reject) => {
    try {
      await checkTokenExpiry();
      const response = await axios.post(`${fast_api_url}/api/getAnswerV3/`, {
        question: question,
        videoId: videoId,
        chatModel: chatModel,
      });
      if (response?.data?.content && response.status === 200) {
        resolve(response?.data);
      } else {
        reject(constants.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      reject(error.message);
    }
  });
};

export const downloadVideoSrt = (videoId) => {
  return new Promise(async (resolve, reject) => {
    try {
       const response =  await axios.get(`${fast_api_url}/api/downloadSrt/${videoId}`, {responseType: 'blob'});
        resolve(response?.data)
    } catch (error) {
      reject(error.message);
    }
  });
};