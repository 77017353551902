import React, {useEffect, useRef} from "react";

import Chat from "./Chat";


const ChatList = ({ chats, isTyping }) => {

  const messageEl = useRef(null);

  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, [])
  return (
    <div ref={messageEl} className="max-h-[300px] overflow-auto">
      <div>
        {chats?.length
          ? chats.map((chat, index) => <Chat chat={chat} key={index} />)
          : null}
      </div>
      {/* {isTyping && <p className="text-xs">Typing...</p>} */}
    </div>
  );
};

export default ChatList;
