import React, { memo } from "react";
import { NavLink } from "react-router-dom";

const Link = ({ link, isOpen, ...props }) => {
  const { title, to, icon } = link;

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center gap-3 py-1 px-3 rounded-md ${
          isActive ? "bg-blue-200" : ""
        }`
      }
    >
      <div className="w-4 h-4">
        <img src={icon} alt={title} className="w-full h-full" />
      </div>
      {isOpen && <p className="text-sm text-gray-500">{title}</p>}
    </NavLink>
  );
};

export default memo(Link);
