import React from "react";
import { useSelector } from "react-redux";
import { IoIosEye } from "react-icons/io";
import { GrDocumentPdf } from "react-icons/gr";

import ChatModal from "../../../../../components/ChatModal";
import {
  formatDate,
  formatSecondsToHourAndMinute,
  trimString,
} from "../../../../../utils/helpers";
import { videosDetailsSelector } from "../../../../../store/reducers/videoDetails/index.reducer";
import {downloadVideoSrt} from '../../../../../api/helpers'

const VideoDescription = () => {
  const { videoDetails, thumbnailURL } = useSelector(videosDetailsSelector);

  const handleDownloadTranscript = async () => {
           const resp =  await downloadVideoSrt(videoDetails?.id)
           const link = document.createElement('a');
            link.target = '_blank';
            link.download = videoDetails.name.split('.')[0];
            link.href = URL.createObjectURL(
                new Blob([resp], { type: resp.type })
            );
            link.click();
  };

  return (
    <div className="flex flex-col justify-between w-full h-full">
      <div className="flex flex-col gap-2">
        <div className="video-detail-cvr">
          <div className="flex flex-row items-center gap-2 ">
            <h1 className="font-semibold text-lg">
              Title: {trimString(videoDetails.name, 15)}
            </h1>
            <ChatModal
              videoDetails={{
                video: videoDetails,
                thumbnail: { data: thumbnailURL },
              }}
            />
          </div>
          <p className="text-sm font-semibold sub-title">
            Creator:{" "}
            <span className="font-normal">{videoDetails.userName}</span>
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex flex-row items-center gap-2">
            <p className="text-xs font-bold flex flex-items gap-1">
              <span>Length:</span>
              <span className="font-normal">
                {formatSecondsToHourAndMinute(videoDetails.durationInSeconds)}
              </span>
            </p>
            <p className="text-xs font-bold flex flex-items gap-1">
              <span>Created:</span>
              <span className="font-normal">
                {formatDate(videoDetails.created)}
              </span>
            </p>
          </div>
          <p className="flex flex-row items-center gap-2 text-xs">
            <IoIosEye className="text-xl" />
            <span>1,324 viewers</span>
          </p>
        </div>
      </div>
      <div>
        <button
          type="button"
          onClick={handleDownloadTranscript}
          className="flex flex-row items-center gap-2 bg-blue-100 px-2 py-1.5 rounded text-xs font-semibold"
        >
          <GrDocumentPdf />
          <span>Download Transcript</span>
        </button>
      </div>
    </div>
  );
};

export default VideoDescription;
