import { useDispatch, useSelector } from "react-redux";
import React, { memo, useEffect, useState } from "react";

import constants from "../../../../utils/constants";
import { getThumbnail } from "../../../../api/helpers";
import {
  changeFace,
  videosDetailsSelector,
} from "../../../../store/reducers/videoDetails/index.reducer";

const Face = ({ face }) => {
  const [thumbnail, setThumbnail] = useState();
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(true);

  const dispatch = useDispatch();
  const {
    videoDetails: { id },
    defaultInsight: { currentFace },
  } = useSelector(videosDetailsSelector);

  const handleMoveToSpecificTime = () => {
    dispatch(changeFace(face));
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getThumbnail(id, face?.thumbnailId);
        setThumbnail(response);
        setIsThumbnailLoading(false);
      } catch (err) {
        setIsThumbnailLoading(false);
      }
    })();
  }, [face?.thumbnailId, id]);

  return (
    <div className="horizontal-scroll-item video-image-item" onClick={handleMoveToSpecificTime}>
      <div
        className={`w-[77px] h-[77px] rounded-full p-1 ${
          currentFace?.id === face?.id ? "border-2 border-blue-800" : ""
        }`}
      >
        {!isThumbnailLoading ? (
          <img
            className="object-cover w-full h-full rounded-3xl"
            src={`${constants.IMAGE_URL_PREFIX}${thumbnail?.data}`}
            alt="thumb"
          ></img>
        ) : null}
      </div>
    </div>
  );
};

export default memo(Face);
