import React from "react";

import VideoPlayer from "./components/VideoPlayer";
import VideoSummary from "./components/VideoSummary";
import VideoDescription from "./components/VideoDescription";

const VideoInfo = () => {
  return (
    <div className="bg-white rounded p-3 border flex flex-col lg:flex-row gap-2 w-full vid-detail-top">
      <div className="flex flex-row gap-4  flex-7  w-full play-with-info">
        <div className="w-80 h-full">
          <VideoPlayer />
        </div>
        <div>
          <VideoDescription />
        </div>
      </div>
      <div className="flex-4 video-summary border-l-2 pl-8 ">
        <VideoSummary />
      </div>
    </div>
  );
};

export default VideoInfo;
