import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeMention, videosDetailsSelector } from "../../../../store/reducers/videoDetails/index.reducer";

const CelebrityDetails = () => {
  const {
    insights: { namedPeople },
    defaultInsight: { currentMention },
  } = useSelector(videosDetailsSelector);
  const dispatch = useDispatch();

  const handleMoveToSpecificTime = (event) => {
    const newBrand = namedPeople?.find(
      (brand) => brand.name === event.target.value
    );
    dispatch(changeMention(newBrand));
  };

  return (
    <div className="mt-4">
      <select
        className="px-2 py-1 border"
        value={currentMention?.name}
        onChange={handleMoveToSpecificTime}
      >
        {namedPeople?.map((brand) => (
          <option
            key={brand.id}
            value={brand.name}
            className={`${
              brand?.instances[0]?.brandType === "Ocr"
                ? "text-green-500"
                : "text-blue-500"
            }`}
          >
            {brand.name} ({brand.instances.length})
          </option>
        ))}
      </select>
      {/* <p className="text-xs">
        {currentMention?.description ? (
          <>
            <span>{trimString(currentMention?.description)}</span>
            {currentMention?.referenceUrl ? (
              <RedirectLink
                href={currentMention?.referenceUrl}
                title="Read More"
                className="font-bold ml-1"
              />
            ) : null}
          </>
        ) : (
          <span className="text-xs">{constants.NO_CONTENT.DESCRIPTION}</span>
        )}
      </p> */}
    </div>
  );
};

export default CelebrityDetails;
