import axios from "axios";

import constants from "../utils/constants";

const instance = axios.create({
  baseURL: constants.API_BASE_URL,
  timeout: 300000,
});

instance.interceptors.request.use(async (config) => {
  try {
    let token = localStorage.getItem(constants.TOKEN_NAME);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error) {
    return config;
  }
});

export const headerConfig = {
  headers: {
    "Ocp-Apim-Subscription-Key": constants.SUBSCRIPTION_KEY,
  },
};

export default instance;
