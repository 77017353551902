import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import HorizontalChart from "../../../../components/HorizontalChart";
import {
  changeBrand,
  videosDetailsSelector,
} from "../../../../store/reducers/videoDetails/index.reducer";

const BrandDetails = () => {
  const {
    insights: { brands },
    defaultInsight: { currentBrand },
  } = useSelector(videosDetailsSelector);
  const dispatch = useDispatch();

  const handleMoveToSpecificTime = (event) => {
    const newBrand = brands.find((brand) => brand.name === event.target.value);
    dispatch(changeBrand(newBrand));
  };

  const chartData = useMemo(() => {
    const labels = brands.map((brand) => brand.name);
    const series = brands.map((brand) => brand.instances.length);

    return {
      labels,
      series,
    };
  }, [brands]);

  return (
    <>
      <div>
        <HorizontalChart data={chartData} />
      </div>
      <div>
        <select
          className="px-2 py-1 border"
          value={currentBrand.name}
          onChange={handleMoveToSpecificTime}
        >
          {brands?.map((brand) => (
            <option
              key={brand.id}
              value={brand.name}
              className={`${
                brand?.instances[0]?.brandType === "Ocr"
                  ? "text-green-500"
                  : "text-blue-500"
              }`}
            >
              {brand.name} ({brand.instances.length})
            </option>
          ))}
        </select>
        {/* <p className="text-xs mt-2">
          {currentBrand?.description ? (
            <>
              <span>{trimString(currentBrand?.description)}</span>
              {currentBrand?.referenceUrl ? (
                <RedirectLink
                  href={currentBrand?.referenceUrl}
                  title="Read More"
                  className="font-bold ml-1"
                />
              ) : null}
            </>
          ) : (
            <span>{constants.NO_CONTENT.DESCRIPTION}</span>
          )}
        </p> */}
      </div>
    </>
  );
};

export default BrandDetails;
