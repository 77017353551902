import DashboardLogo from "../assets/Dashboard.svg";
import LibraryLogo from "../assets/Library.svg";
import IntegrationLogo from "../assets/Integrations.svg";
import SupportLogo from "../assets/Support.svg";
import SettingsLogo from "../assets/Settings.svg";

const navLinks = {
  links: [
    { title: "Dashboard", to: "/dashboard", icon: DashboardLogo },
    { title: "library", to: "/library", icon: LibraryLogo },
    { title: "Integrations", to: "/integrations", icon: IntegrationLogo },
  ],
  supportLinks: [
    { title: "Help", to: "/support", icon: SupportLogo },
    { title: "Settings", to: "/settings", icon: SettingsLogo },
  ],
};

export default navLinks;
