import React from "react";

import "./index.css";
import TimeFrame from "./TimeFrame";

const HorizontalTimeline = ({ timeline }) => {
  if (!timeline?.length) {
    return null;
  }

  return (
    <div className="w-full mt-4">
      <h6 className="text-xs underline">Instances</h6>
      <svg
        width="100%"
        height="25"
        xmlns="http://www.w3.org/2000/svg"
        tabIndex="-1"
        className="border-none outline-none"
      >
        <rect
          width="100%"
          height="12"
          x="0%"
          y="5"
          className="transition bar cursor-pointer"
        ></rect>
        {timeline?.map((time, index) => (
          <TimeFrame key={index} time={time} />
        ))}
      </svg>
    </div>
  );
};

export default HorizontalTimeline;
