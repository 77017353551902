import { createAsyncThunk } from "@reduxjs/toolkit";

import { getThumbnail, getVideoIndexDetails } from "../../../api/helpers";

export const fetchVideoDetails = createAsyncThunk(
  "videoDetails/fetchVideoDetails",
  async (payload, thunkAPI) => {
    try {
      const videoDetails = await getVideoIndexDetails(payload?.id);

      const thumbnail = await getThumbnail(
        videoDetails.id,
        videoDetails?.summarizedInsights?.thumbnailId
      );

      return {
        videoDetails: videoDetails,
        insights: videoDetails?.videos[0]?.insights,
        thumbnailURL: thumbnail?.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
