import { useSelector } from "react-redux";
import React, { memo, useMemo } from "react";

import Face from "./Face";
import FaceDetails from "./FaceDetails";
import InsightSection from "../InsightSection";
import HorizontalTimeline from "../HorizontalTimeline";
import { getTimelineInfo } from "../../../../utils/helpers";
import { videosDetailsSelector } from "../../../../store/reducers/videoDetails/index.reducer";

const Faces = () => {
  const {
    defaultInsight: { currentFace },
    insights: { faces },
  } = useSelector(videosDetailsSelector);

  const timeline = useMemo(
    () => currentFace?.instances?.map((face) => getTimelineInfo(face)),
    [currentFace?.instances]
  );

  if (!faces?.length) {
    return null;
  }

  return (
    <InsightSection title="faces" count={faces?.length}>
      <div className="flex flex-col w-full gap-4">
        <div className="horizontal-scroll video-image-item">
          {faces?.map((face) => (
            <Face face={face} key={face?.id} />
          ))}
        </div>
        <div className="bg-gray-100 rounded px-2 py-1">
          <FaceDetails />
          <HorizontalTimeline timeline={timeline} />
        </div>
      </div>
    </InsightSection>
  );
};

export default memo(Faces);
