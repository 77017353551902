import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import InsightSection from "../InsightSection";
import LineChart from "../../../../components/LineChart";
import { getTimeInSeconds } from "../../../../utils/helpers";
import { videosDetailsSelector } from "../../../../store/reducers/videoDetails/index.reducer";

const FRAGMENT_SIZE = 60;

const Sentiments = () => {
  const {
    videoDetails: { durationInSeconds },
    insights: { sentiments },
  } = useSelector(videosDetailsSelector);

  const getSentimentOccurrences = (emotionInstances, segments) => {
    const emotionOccurrences = emotionInstances
      ? emotionInstances.instances?.map((instance) =>
          getTimeInSeconds(instance.start)
        )
      : [];

    const occurrences = [];
    for (let segment of segments) {
      const filledValue = emotionOccurrences.find(
        (el) => el >= segment && el < segment + FRAGMENT_SIZE
      );
      const value = filledValue ? emotionInstances.averageScore * 100 : 0;
      occurrences.push(value);
    }

    return occurrences;
  };

  const chartData = useMemo(() => {
    const minutes = Math.ceil(durationInSeconds / FRAGMENT_SIZE);
    const fragments = Array(minutes).fill(0);

    const labels = fragments.map((el, index) => index * (60 / FRAGMENT_SIZE));
    const segments = labels.map((el, index) => index * FRAGMENT_SIZE);

    const emotions = {};
    for (let sentiment of sentiments) {
      emotions[sentiment.sentimentType.toLowerCase()] = sentiment
    }

    const neutral = emotions["neutral"];
    const positive = emotions["positive"];
    const negative = emotions["negative"];
    const neutralOccurrences = getSentimentOccurrences(neutral, segments);
    const positiveOccurrences = getSentimentOccurrences(positive, segments);
    const negativeOccurrences = getSentimentOccurrences(negative, segments);

    const datasets = [
      {
        label: `Neutral ${
          neutral?.averageScore ? Math.ceil(neutral?.averageScore * 100) : 0
        }%`,
        data: neutralOccurrences,
        backgroundColor: "#7EB74F",
        borderColor: "#7EB74F",
        //fill: true,
      },
      {
        label: `Positive ${
          positive?.averageScore ? Math.ceil(positive?.averageScore * 100) : 0
        }%`,
        data: positiveOccurrences,
        backgroundColor: "#A17EB8",
        borderColor: "#A17EB8",
        //fill: true,
      },
      {
        label: `Negative ${
          negative?.averageScore ? Math.ceil(negative?.averageScore) * 100 : 0
        }%`,
        data: negativeOccurrences,
        backgroundColor: "#ED9F6A",
        borderColor: "#ED9F6A",
        //fill: true,
      },
    ];

    return {
      labels,
      datasets: datasets?.sort((a, b) =>
        a.data[0] > b.data[0] ? 1 : a.data[0] < b.data[0] ? -1 : 1
      ),
    };
  }, [sentiments, durationInSeconds]);

  if (!sentiments?.length) {
    return null;
  }

  return (
    <InsightSection title="Sentiments" childClassName="pb-3 px-2 pt-0 -mt-3">
      <LineChart data={chartData} title="Sentiment over time" />
    </InsightSection>
  );
};

export default Sentiments;
