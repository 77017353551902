import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";

import ChatBox from "./ChatBox";
import chatLogo from "../assets/chat.svg";
import constants from "../utils/constants.js";
import { trimString } from "../utils/helpers.js";
import VidableChat from "../assets/VidableChat.png";

const ChatModal = ({ buttonIcon, videoDetails: { video, thumbnail } }) => {
  const [open, setOpen] = React.useState(false);
  const [expandText, setExpandText] = React.useState(false);
  const [selectedChatModel, setselectModel] = React.useState('text-davinci-003');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const toggleExpand = () => {
    setExpandText(!expandText);
  };

  const chatModels = [
    { name: 'text-davinci-003', id: 1 },
    { name: 'gpt-3.5-turbo', id: 2 },
  ]
  const models = {
    selectedModel: "",
  };
  const onChatModelChange = (e) => setselectModel(e.target.value);
  return (
    <div className="">
      <button
        title="Vidable Chat"
        type="button"
        className="w-[25px] h-[25px] disabled:cursor-not-allowed disabled:text-gray-300"
        onClick={handleOpen}
        disabled={""}
      >
        <img src={chatLogo} alt="chat" className="w-full h-full object-fill" />
        {/*<FontAwesomeIcon icon={buttonIcon} />*/}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="rounded-lg"
          sx={{
            position: "absolute",
            top: "5%",
            right: "50px",
            width: expandText ? 800 : 350,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <FontAwesomeIcon
            className="absolute -top-2 -right-2 z-10 cursor-pointer"
            onClick={handleClose}
            fontSize={24}
            icon={faCircleXmark}
          />
          <div className="flex flex-col gap-3 relative">
            <div className="flex flex-row items-center justify-between">
              <Link to={constants.ROUTES.LIBRARY}>
                <img src={VidableChat} alt="logo-vid" height={50} width={100} />
              </Link>
              <select
                className="px-4 text-sm border bg-gray-50 rounded border-gray-400"
                value={models.value}
                onChange={onChatModelChange}
              >
                {chatModels.map((msgTemplate) => {
                  return (
                    <option
                      key={msgTemplate.id}
                      name={msgTemplate.name}
                      value={msgTemplate.name}
                    >
                      {msgTemplate.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="w-full h-48 rounded overflow-hidden">
              <img
                className="w-full h-full object-cover"
                alt="thumbnail"
                src={`${constants.IMAGE_URL_PREFIX}${thumbnail?.data}`}
              />
            </div>
            <div className="flex flex-row items-end justify-between">
              <div>
                <h3 className="text-sm font-semibold text-gray-600">
                  {trimString(video.name, 30)}
                </h3>
                <p className="text-xs font-semibold">
                  Creator:{" "}
                  <span className="text-gray-600">{video.userName}</span>
                </p>
              </div>
              <button
                onClick={toggleExpand}
                type="button"
                aria-label={expandText ? "Collapse" : "Expand"}
              >
                <FontAwesomeIcon
                  fontSize={12}
                  icon={
                    expandText
                      ? faDownLeftAndUpRightToCenter
                      : faUpRightAndDownLeftFromCenter
                  }
                />
              </button>
            </div>
            <ChatBox currentGPTModel={selectedChatModel} videoData= {video} />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ChatModal;
