import { useSelector } from "react-redux";
import React, { memo, useMemo } from "react";

import TopicDetails from "./TopicDetails";
import InsightSection from "../InsightSection";
import HorizontalTimeline from "../HorizontalTimeline";
import { getTimelineInfo } from "../../../../utils/helpers";
import HorizontalChart from "../../../../components/HorizontalChart";
import { videosDetailsSelector } from "../../../../store/reducers/videoDetails/index.reducer";

const Topics = () => {
  const {
    defaultInsight: { currentTopic },
    insights: { topics },
  } = useSelector(videosDetailsSelector);

  const timeline = useMemo(
    () => currentTopic?.instances?.map((topic) => getTimelineInfo(topic)),
    [currentTopic?.instances]
  );

  const chartData = useMemo(() => {
    const labels = topics?.map((topic) => topic?.name);
    const series = topics?.map((topic) => topic?.instances.length);

    return {
      labels,
      series,
    };
  }, [topics]);

  if (!topics?.length) {
    return null;
  }

  return (
    <InsightSection title="Topics" childClassName="pb-3 px-2 pt-0 -mt-2">
      <HorizontalChart data={chartData} title="Count" label="Locations" />
      <div className="flex flex-col w-full gap-3">
        <TopicDetails />
      </div>
      <HorizontalTimeline timeline={timeline} />
    </InsightSection>
  );
};

export default memo(Topics);
