import React from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Link from "../../../components/Link";
import navLinks from "../../../common/links";

const Sidebar = ({ setIsOpen, isOpen }) => {
  return (
    <div className={`flex flex-col w-full h-full`}>
      <div className="flex justify-end px-3 py-2 border-b-2 border-b-slate-100 sidebar-toggle">
        {isOpen ? (
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="cursor-pointer"
            onClick={() => setIsOpen(isOpen === true ? false : true)}
          />
        ) : (
          <FontAwesomeIcon
            icon={faArrowRight}
            className="cursor-pointer"
            onClick={() => setIsOpen(isOpen === true ? false : true)}
          />
        )}
      </div>
      <div className="flex-1 flex flex-col px-5 py-5 gap-3 border-b-2 border-b-slate-100">
        {navLinks.links.map((link) => (
          <Link link={link} key={link?.to} isOpen={isOpen} />
        ))}
      </div>
      <div className="flex flex-col px-5 py-5 gap-3">
        {navLinks.supportLinks.map((link) => (
          <Link link={link} key={link?.to} isOpen={isOpen} />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
