// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.places-container {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 300px;
}

.map-container {
    width: 100%;
    height: 350px;
}

.LocationMap * {
    overflow: unset;
}`, "",{"version":3,"sources":["webpack://./src/pages/VideoDetails/components/Places/Map.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["\n.places-container {\n    position: absolute;\n    top: 10px;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: 10;\n    width: 300px;\n}\n\n.map-container {\n    width: 100%;\n    height: 350px;\n}\n\n.LocationMap * {\n    overflow: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
