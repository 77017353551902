import { useSelector } from "react-redux";
import React, { memo, useMemo } from "react";

import PlaceDetails from "./PlaceDetails";
import InsightSection from "../InsightSection";
import HorizontalTimeline from "../HorizontalTimeline";
import { getTimelineInfo } from "../../../../utils/helpers";
import DoughnutChart from "../../../../components/DoughnutChart";
import { videosDetailsSelector } from "../../../../store/reducers/videoDetails/index.reducer";
import Map from "./Map";

const Places = () => {
  const {
    defaultInsight: { currentPlace },
    insights: { namedLocations },
  } = useSelector(videosDetailsSelector);

  const timeline = useMemo(
    () => currentPlace?.instances?.map((topic) => getTimelineInfo(topic)),
    [currentPlace?.instances]
  );

  const startWithCapital = (namedLocations) => {
    const result = namedLocations?.filter(
      (f) => f?.name?.charAt(0) === f?.name?.charAt(0)?.toUpperCase()
    );
    return result;
  };

  const filteredPlaces = startWithCapital(namedLocations);

  const chartData = useMemo(() => {
    const labels = filteredPlaces?.map((location) => location?.name);
    const series = filteredPlaces?.map(
      (location) => location?.instances?.length
    );

    return {
      labels,
      series,
    };
  }, [filteredPlaces]);

  if (!filteredPlaces?.length) {
    return null;
  }

  return (
    <InsightSection title="Locations" childClassName="pb-3 px-2 pt-0">
      <div className="w-full">
        <Map filteredPlaces={filteredPlaces} />
        {/* <DoughnutChart data={chartData} label="Location" width="300px" /> */}
      </div>
      <div className="flex flex-col w-full gap-3">
        <PlaceDetails />
      </div>
      <HorizontalTimeline timeline={timeline} />
    </InsightSection>
  );
};

export default memo(Places);
