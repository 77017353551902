import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import InsightSection from "../InsightSection";
import StackBarChart from "../../../../components/StackBarChart";
import { videosDetailsSelector } from "../../../../store/reducers/videoDetails/index.reducer";

const SentimentByBrand = () => {
  const {
    insights: { brands },
  } = useSelector(videosDetailsSelector);

  const chartData = useMemo(() => {
    const labels = brands?.map((brand) => brand?.name);
    const series = brands?.map((brand) => brand?.instances?.length);

    return {
      labels,
      series,
    };
  }, [brands]);

  if (!brands?.length) {
    return null;
  }
  return (
    <InsightSection title="Sentiment by Brand" className="flex-1">
      <div className="flex flex-col w-full -mt-10">
        <StackBarChart data={chartData} />
      </div>
    </InsightSection>
  );
};

export default SentimentByBrand;
