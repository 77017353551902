import React from "react";

import Faces from "../Faces";
import Brands from "../Brands";
import Sentiments from "../Sentiments";
import SentimentByBrand from "../SentimentByBrand";

const AnalysisLeft = () => {
  return (
    <div className="flex flex-col gap-2">
      <Faces />
        <Brands />
        <SentimentByBrand />
      <Sentiments />
    </div>
  );
};

export default AnalysisLeft;
