import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

const SummaryModal = ({ summary }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="">
      <button
        title="more"
        type="button"
        className="disabled:cursor-not-allowed disabled:text-gray-300"
        onClick={handleOpen}
        disabled={""}
      >
        <span className="text-blue-500 font-bold ml-1 text-sm flex flex-row items-center cursor-pointer">
          More
          <MdKeyboardArrowRight />
        </span>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" 
        className="modal-parrent"       
      >
        <Box
          className="rounded-lg modal-wrap"  
        >
          <div className="modal-title"><h2 className="font-bold text-lg">Summary</h2>  
           <FontAwesomeIcon
            className="absolute top-3 right-2 z-10 cursor-pointer"
            onClick={handleClose}
            fontSize={24}
            icon={faCircleXmark}
          />
          </div>
          <p>{summary}</p>
        
        </Box>
      </Modal>
    </div>
  );
};

export default SummaryModal;
