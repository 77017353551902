import * as React from "react";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Modal from "@mui/material/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import constants from "../utils/constants";
import CustomerLoader from "./CustomerLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const ConfirmationModal = ({
  title,
  buttonIcon,
  handleSubmit,
  disabled = false,
  submitText = "Delete",
  cancelText = "Cancel",
  message = constants.DELETE_PROMPT_DEFAULT_MESSAGE,
  isDeleteProcessing = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <button
        type="button"
        title="Delete"
        className="disabled:cursor-not-allowed disabled:text-gray-300"
        onClick={handleOpen}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={buttonIcon} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col gap-3">
            <h3 className="text-3xl">{title}</h3>
            <Divider />
            <p>{message}</p>
            <div className="flex flex-row justify-end gap-6 mt-6">
              <button
                type="button"
                onClick={handleClose}
                disabled={isDeleteProcessing}
                className="bg-gray-100 px-4 py-2 border rounded hover:scale-105"
              >
                {cancelText}
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                disabled={isDeleteProcessing}
                className="bg-red-500 px-4 py-2 border border-white text-white rounded hover:scale-105"
              >
                {isDeleteProcessing ? (
                  <CustomerLoader />
                ) : (
                  <span>{submitText}</span>
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
