// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transition {
  transform-origin: left;
  transition: transform 0.2s, opacity 0.2s, x 0.2s, width 0.2s;
}

.bar {
  fill: rgba(0, 0, 0, 0.05);
}

.item {
  fill: black;
}
`, "",{"version":3,"sources":["webpack://./src/pages/VideoDetails/components/HorizontalTimeline/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EAItB,4DAA4D;AAC9D;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb","sourcesContent":[".transition {\n  transform-origin: left;\n  -moz-transition: transform 0.2s, opacity 0.2s, x 0.2s, width 0.2s;\n  -o-transition: transform 0.2s, opacity 0.2s, x 0.2s, width 0.2s;\n  -webkit-transition: transform 0.2s, opacity 0.2s, x 0.2s, width 0.2s;\n  transition: transform 0.2s, opacity 0.2s, x 0.2s, width 0.2s;\n}\n\n.bar {\n  fill: rgba(0, 0, 0, 0.05);\n}\n\n.item {\n  fill: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
