import React, { memo } from "react";

import { filterDefaultOptions } from "../../../../common/static";

const InsightSection = ({
  title,
  count = 0,
  children,
  className = "",
  childClassName = "",
  isFilteredEnabled = false,
  options = filterDefaultOptions,
  filterTitle = "# of occurrences",
  ...props
}) => {
  return (
    <div className={`rounded border ${className}`}>
      <div className="p-3 flex justify-between text-sm bg-white items-center">
        <div className="flex flex-row gap-2 items-center">
          <h1 className="font-semibold capitalize text-black">{title}</h1>
          {count ? (
            <p className="text-xs font-semibold text-gray-400">
              ({count} item{count > 1 ? "s" : ""})
            </p>
          ) : null}
        </div>
      </div>
      <div
        className={`p-3 flex flex-row flex-wrap gap-x-3 gap-y-2 bg-white ${childClassName}`}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(InsightSection);
