import { createSlice } from "@reduxjs/toolkit";

import { fetchAllVideos } from "./index.thunk";

const initialState = {
  isLoading: true,
  videos: [],
};

export const videosSlice = createSlice({
  name: "videos",
  initialState: initialState,
  reducers: {
    deleteByVideoId: (state, action) => {
      const { videoId } = action.payload;
      const filteredVideos = state?.videos?.filter(
        (video) => video?.id !== videoId
      );

      state.videos = filteredVideos;
    },
    updateVideoProcessingState: (state, action) => {
      const { videoId, videoProcessingState, videoState, thumbnailId } =
        action.payload;
      const updatedVideos = state?.videos?.map((video) =>
        video.id === videoId
          ? {
              ...video,
              processingProgress: videoProcessingState,
              state: videoState,
              thumbnailId: thumbnailId,
            }
          : video
      );

      state.videos = updatedVideos;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllVideos.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchAllVideos.fulfilled, (state, action) => {
        state.videos = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAllVideos.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const videosSelector = (state) => state.videos;

export const { deleteByVideoId, updateVideoProcessingState } =
  videosSlice.actions;

export default videosSlice.reducer;
