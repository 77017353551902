import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { getSeenDuration } from "../../../../utils/helpers";
import { videosDetailsSelector } from "../../../../store/reducers/videoDetails/index.reducer";

const FaceDetails = () => {
  const {
    videoDetails: { durationInSeconds },
    defaultInsight: {
      currentFace: { instances, name },
    },
  } = useSelector(videosDetailsSelector);

  const seenDuration = useMemo(
    () => getSeenDuration(instances, durationInSeconds),
    [instances, durationInSeconds]
  );

  return (
    <div className="flex flex-col">
      <h4 className="text-base">{name}</h4>
      <p className="text-xs">Appears in {seenDuration?.toFixed(2)}% of video</p>
    </div>
  );
};

export default FaceDetails;
