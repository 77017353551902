import React from "react";

import Topics from "../Topics";
import Mentions from "../Mentions";
import Places from "../Places";

const AnalysisRight = () => {
  return (
    <div className="flex flex-col gap-2 w-full">
      <Topics />
      <Places />
      <Mentions />
    </div>
  );
};

export default AnalysisRight;
