import { createSlice } from "@reduxjs/toolkit";

import { fetchVideoDetails } from "./index.thunk";
import { sortInsightByInstances } from "../../../utils/helpers";

const initialState = {
  videoDetails: {},
  insights: {},
  thumbnailURL: "",
  isLoading: true,
  currentStartTime: 0,
  defaultInsight: {
    currentKeyword: {},
    currentLabel: {},
    currentPlace: {},
    currentTopic: {},
    currentBrand: {},
    currentFace: {},
    currentEmotion: {},
    currentMention: {},
  },
  filterDefaults: {
    keywords: 0,
    labels: 0,
  },
  summary: "",
};

export const videoDetailsSlice = createSlice({
  name: "videoDetails",
  initialState: initialState,
  reducers: {
    changeStartTime: (state, action) => {
      const { time } = action?.payload;
      state.currentStartTime = time;
    },
    changeKeyword: (state, action) => {
      state.defaultInsight.currentKeyword = action.payload;
    },
    changeLabel: (state, action) => {
      state.defaultInsight.currentLabel = action.payload;
    },
    changePlace: (state, action) => {
      state.defaultInsight.currentPlace = action.payload;
    },
    changeTopic: (state, action) => {
      state.defaultInsight.currentTopic = action.payload;
    },
    changeBrand: (state, action) => {
      state.defaultInsight.currentBrand = action.payload;
    },
    changeFace: (state, action) => {
      state.defaultInsight.currentFace = action.payload;
    },
    changeEmotion: (state, action) => {
      state.defaultInsight.currentEmotion = action.payload;
    },
    changeMention: (state, action) => {
      state.defaultInsight.currentMention = action.payload;
    },
    changeFilterDefaultsValue: (state, action) => {
      const { type, value } = action.payload;
      state.filterDefaults[type] = value;
    },
    changeSummary: (state, action) => {
      state.summary = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideoDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchVideoDetails.fulfilled, (state, action) => {
        const { videoDetails, thumbnailURL, insights } = action.payload;
        const { namedLocations, faces, emotions } = insights;

        const sortedKeywords = sortInsightByInstances(insights?.keywords);
        const sortedLabels = sortInsightByInstances(insights?.labels);
        const sortedTopics = sortInsightByInstances(insights?.topics);
        const sortedPeople = sortInsightByInstances(
          insights?.namedPeople,
          "asc"
        );
        const sortedBrands = sortInsightByInstances(insights?.brands);

        state.videoDetails = videoDetails;
        state.thumbnailURL = thumbnailURL;
        state.isLoading = false;

        state.filterDefaults.keywords = 0;
        state.filterDefaults.labels = 0;

        state.insights = insights;
        state.insights.keywords = sortedKeywords;
        state.defaultInsight.currentKeyword = sortedKeywords?.length
          ? sortedKeywords[0]
          : {};

        state.insights.labels = sortedLabels;
        state.defaultInsight.currentLabel = sortedLabels?.length
          ? sortedLabels[0]
          : {};

        state.defaultInsight.currentPlace = namedLocations?.length
          ? namedLocations[0]
          : {};

        state.insights.topics = sortedTopics;
        state.defaultInsight.currentTopic = sortedTopics?.length
          ? sortedTopics[0]
          : {};

        state.insights.brands = sortedBrands;
        state.defaultInsight.currentBrand = sortedBrands?.length ? sortedBrands[0] : {};

        state.defaultInsight.currentFace = faces?.length ? faces[0] : {};
        state.defaultInsight.currentEmotion = emotions?.length
          ? emotions[0]
          : {};

        state.insights.namedPeople = sortedPeople;
        state.defaultInsight.currentMention = sortedPeople?.length
          ? sortedPeople[0]
          : {};
      })
      .addCase(fetchVideoDetails.rejected, (state, action) => {
        state.videoDetails = {};
        state.isLoading = false;
      });
  },
});

export const videosDetailsSelector = (state) => state.videoDetails;

export const {
  changeStartTime,
  changeKeyword,
  changeLabel,
  changePlace,
  changeTopic,
  changeBrand,
  changeFace,
  changeEmotion,
  changeMention,
  changeFilterDefaultsValue,
  changeSummary,
} = videoDetailsSlice.actions;

export default videoDetailsSlice.reducer;
