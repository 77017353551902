import React, { useMemo } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { faHomeLg, faPlus } from "@fortawesome/free-solid-svg-icons";

import FileUploader from "../../components/FileUploader";
import { useSelector } from "react-redux";
import { videosDetailsSelector } from "../../store/reducers/videoDetails/index.reducer";
//import constants from "../../utils/constants";

const Library = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { videoDetails } = useSelector(videosDetailsSelector)

  const main = useMemo(
    () => location?.pathname?.split("/").filter((el) => el),
    [location.pathname]
  );

  const handleGoToLibrary = () => {
    try {
      navigate('/library');
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <section className="flex flex-col w-full h-full p-2 gap-1">
      <header className="flex justify-between add-video-header items-center flex-06">
        <div className="flex gap-1 items-center">
          <FontAwesomeIcon
            icon={faHomeLg}
            className="hover:scale-105 cursor-pointer"
            onClick={handleGoToLibrary}
          />
          <span>/</span>
          <span
            className="text-sm text-gray-500 hover:text-blue-500 cursor-pointer hover:underline underline-offset-4"
            onClick={handleGoToLibrary}
          >
            {main[0]}
          </span>
          {main[1] && videoDetails?.name && (
            <>
              <span>/</span>
              <span className="text-sm text-gray-500">
                {videoDetails?.name?.split('.')[0]}
              </span>
            </>
          )}
        </div>
        <FileUploader buttonIcon={faPlus} buttonText="Add Video" />
      </header>
      {location?.pathname === "/library" && (
        <div className="my-3 flex-05 bread-crumb">
          <h3 className="text-xl font-bold ">Library</h3>
        </div>
      )}
      <div className="flex-100 overflow-auto w-full h-full">
        <Outlet />
      </div>
    </section>
  );
};

export default Library;
