import React, { useEffect, useState } from "react";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const redirectUrl = location.pathname === '/' ? '/library' : location.pathname;
    navigate(redirectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full flex flex-col gap-2 bg-gray-100">
      <Header />
      <div className="flex w-full h-full">
        <div
          className={`${
            isOpen ? "w-64 side-bar-open" : "w-20 side-bar-close"
          } sidebar transition-all bg-white`}
        >
          <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <div
          className={`flex-100 main-container ${
            isOpen ? "side-bar-open" : "side-bar-close"
          }`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
