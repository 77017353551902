import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HorizontalChart = ({ data, title, label }) => {
  return (
    <Bar
      options={{
        maintainAspectRatio: true,
        indexAxis: "y",
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        plugins: {
          legend: {
            position: "right",
            display: false,
          },
          title: {
            display: true,
            text: title,
            position: "bottom"
          },
        },
      }}
      data={{
        labels: data.labels,
        datasets: [
          {
            barPercentage: 0.9,
            categoryPercentage: 1,
            label: label,
            data: data.series,
            backgroundColor: "rgba(30, 139, 195, 1)",
            //barThickness: 20,
            //maxBarThickness: 20,
          },
        ],
      }}
    />
  );
};

export default HorizontalChart;
