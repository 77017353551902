import React, { memo, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Video from "./Video";
import constants from "../../../utils/constants";
import Loading from "../../../components/Loading";
import NoContent from "../../../components/NoContent";
import { fetchAllVideos } from "../../../store/reducers/videos/index.thunk";
import { videosSelector } from "../../../store/reducers/videos/index.reducer";

const VideoContent = () => {
  const dispatch = useDispatch();
  const { isLoading, videos } = useSelector(videosSelector);
  const shouldRender = useRef(true);

  const getAllVideos = useCallback(() => {
    dispatch(fetchAllVideos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!videos?.length) {
      if (shouldRender.current) {
        getAllVideos();
        shouldRender.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="video-list-wrap p-2 rounded-sm overflow-auto flex flex-row flex-wrap gap-x-2 gap-y-2">
      {isLoading ? (
        <Loading />
      ) : videos?.length ? (
        <div className="vid-wrap">
          <h5>Select videos</h5>
          <div className=" flex flex-row flex-wrap gap-x-2 gap-y-2">
            {" "}
            {videos?.map((video) => (
              <Video video={video} key={video?.id} />
            ))}
          </div>
        </div>
      ) : (
        <NoContent message={constants.NO_VIDEO_FOUND} />
      )}
    </div>
  );
};

export default memo(VideoContent);
