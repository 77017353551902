import { toast } from "react-toastify";
import { IoSend } from "react-icons/io5";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import ChatList from "./ChatList";
import CustomerLoader from "../../components/CustomerLoader";
import { getVideoSrt, getOpenAiResV3 } from "../../api/helpers";

const ChatBox = ({ currentGPTModel, videoData }) => {
  const [chats, setChats] = useState([]);
  const [question, setQuestion] = useState();
  const [isLoading, setLoading] = useState();
  const [isTyping, setIsTyping] = useState(false);

  const handleChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (question) {
        setIsTyping(true);

        let msgs = chats;
        setChats(msgs);
        setLoading(true);

        const res = await getOpenAiResV3(
          videoData?.id,
          question,
          currentGPTModel
        );

        msgs.push({ role: "user", content: question });
        setQuestion('');
        msgs.push(res);
        setChats(msgs);
        setIsTyping(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const handleChatStarterSubmit = async (starterMessage) => { 
    try{
      if(starterMessage) {
        setQuestion(starterMessage);
      }
    }catch (error) {
      toast.error(error.message);
    }
  }


  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (videoData?.id) {
          await getVideoSrt(videoData?.id, "txt");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    })();
  }, [videoData?.id]);

  return (
    <div className="mt-2 flex flex-col gap-2">
      <ChatList isTyping={isTyping} chats={chats} />
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <form onSubmit={handleSubmit}>
            <div className="bg-blue-50 p-3 rounded flex flex-row items-center gap-1">
              <input
                value={question}
                onChange={handleChange}
                type="text"
                placeholder="Type your chat here"
                required
                className="w-full h-full bg-transparent outline-none border-none italic text-xs"
              />
              <button disabled={isLoading || !question} type="submit">
                {isLoading ? <CustomerLoader /> : <IoSend />}
              </button>
            </div>
          </form>
          <div className="flex flex-row items-center gap-3">
            <label className="text-gray-500 text-xs flex flex-row items-center gap-1">
              <input type="checkbox" /> <span>Include internet response</span>
            </label>
            <label className="text-gray-500 text-xs flex flex-row items-center gap-1">
              <input type="checkbox" /> <span>Search entire library</span>
            </label>
          </div>
        </div>
        {!chats?.length ? (
          <div className="flex flex-col gap-1">
            <h4 className="text-sm font-bold">Chat Starters</h4>
            <div className="flex flex-col gap-1">
              <div>
                <button
                  className="px-2 py-1 bg-gray-200 text-xs rounded"
                  type="button"
                  onClick={() => handleChatStarterSubmit('Generate a summary of the presentation')}
                >
                  Generate a summary of the presentation
                </button>
              </div>
              <div>
                <button
                  className="px-2 py-1 bg-gray-200 text-xs rounded"
                  type="button"
                  onClick={() =>handleChatStarterSubmit('What could I improve')}
                >
                  What could I improve
                </button>
              </div>
              <div>
                <button
                  className="px-2 py-1 bg-gray-200 text-xs rounded"
                  type="button"
                  onClick={() =>handleChatStarterSubmit('Generate a social media post for this presentation')}
                >
                  Generate a social media post for this presentation
                </button>
              </div>
              <div>
                <button
                  className="px-2 py-1 bg-gray-200 text-xs rounded flex flex-row items-center gap-2"
                  type="button"
                  onClick={() => {}}
                >
                  <span>Other options</span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChatBox;
