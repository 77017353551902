import React, { memo } from "react";

const ComingSoon = ({ message = "Coming Soon", ...props }) => {
  return (
    <div
      className="w-full h-full flex flex-col items-center justify-center"
      {...props}
    >
      <p className="mb-4 font-bold text-xl">{message}</p>
      <span className="text-md color">Please go to library for videos</span>
    </div>
  );
};

export default memo(ComingSoon);
