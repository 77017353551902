import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VerticalChart = ({ data, title, label }) => {
  return (
    <Bar
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            display: false,
          },
          title: {
            display: true,
            text: title,
          },
        },
      }}
      data={{
        labels: data.labels,
        datasets: [
          {
            label: label,
            data: data.series,
            backgroundColor: "rgba(30, 139, 195, 1)",
          },
        ],
      }}
    />
  );
};

export default VerticalChart;
