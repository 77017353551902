import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faTrash,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import React, {
  useEffect,
  useState,
  useCallback,
  memo,
  useMemo,
  useRef,
} from "react";

import constants from "../../../utils/constants";
import CustomerLoader from "../../../components/CustomerLoader";
import ConfirmationModal from "../../../components/ConfirmationModal";
import ChatModal from "../../../components/ChatModal";
import {
  deleteByVideoId,
  updateVideoProcessingState,
} from "../../../store/reducers/videos/index.reducer";
import {
  deleteVideo,
  getAllVideos,
  getThumbnail,
  generateSummaryForVideo,
} from "../../../api/helpers";
import { trimString } from "../../../utils/helpers";

const Video = ({ video }) => {
  const { id, thumbnailId } = video;

  const [thumbnail, setThumbnail] = useState("");
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(true);
  const [isDeleteProcessing, setIsDeleteProcessing] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  const dispatch = useDispatch();
  const shouldRender = useRef(true);

  const isProcessedVideo = useMemo(
    () => video?.state?.toLowerCase() === constants.VIDEO_PROCESSED,
    [video?.state]
  );

  const navigate = useNavigate();

  const handleGetIntoVideo = useCallback(() => {
    try {
      if (isProcessedVideo) {
        navigate(`/library/${video.id}`);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  }, [navigate, video.id, isProcessedVideo]);

  const handleDeleteVideo = async () => {
    try {
      if (isProcessedVideo) {
        setIsDeleteProcessing(true);
        await deleteVideo(video?.id);
        dispatch(deleteByVideoId({ videoId: video?.id }));
        setIsDeleteProcessing(false);
        toast.success(constants.SUCCESS_MESSAGE.VIDEO_DELETED);
      }
    } catch (error) {
      setIsDeleteProcessing(false);
      toast.error(error?.message);
    }
  };

  const handleImgLoadError = (error) => {
    setIsImageError(true);
  };

  useEffect(() => {
    (async () => {
      try {
        if (shouldRender.current) {
          const response = await getThumbnail(id, thumbnailId);
          setIsImageError(false);
          setThumbnail(response);
          shouldRender.current = false;
        }
        setIsThumbnailLoading(false);
      } catch (error) {
        setIsImageError(true);
        toast.error(error?.message);
        setIsThumbnailLoading(false);
      }
    })();
  }, [id, thumbnailId]);

  useEffect(() => {
    let interval;
    interval = setInterval(async () => {
      try {
        if (video?.state?.toLowerCase() !== constants.VIDEO_PROCESSED) {
          const videos = await getAllVideos();
          const currentVideoState = videos.find((vid) => vid.id === video.id);
          if (
            currentVideoState?.state?.toLowerCase() ===
              constants.VIDEO_PROCESSED &&
            currentVideoState?.processingProgress === "100%"
          ) {
            await generateSummaryForVideo(video.id);
          }
          dispatch(
            updateVideoProcessingState({
              videoId: video?.id,
              videoProcessingState: currentVideoState?.processingProgress,
              videoState: currentVideoState?.state,
              thumbnailId: currentVideoState?.thumbnailId,
            })
          );

          if (!thumbnail) {
            const response = await getThumbnail(
              id,
              currentVideoState?.thumbnailId
            );
            setThumbnail(response);
          }
        } else {
          await generateSummaryForVideo(video.id);
          clearInterval(interval);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }, constants.VIDEO_PROCESSING_API_CALL);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video?.state, video?.id]);

  return (
    <div className="bg-white w-[250px] h-[215px] video-snip-box rounded-sm p-1 border flex flex-col">
      <div
        className={`w-full h-[140px] overflow-hidden min-w-full relative ${
          isProcessedVideo ? "cursor-pointer" : "cursor-not-allowed"
        }`}
        onClick={handleGetIntoVideo}
      >
        {!isThumbnailLoading ? (
          <>
            {!isImageError && (
              <img
                className="object-cover w-full rounded-sm"
                src={`${constants.IMAGE_URL_PREFIX}${thumbnail?.data}`}
                alt="thumb"
                onError={handleImgLoadError}
              />
            )}
            <div className="bg-black bg-opacity-40 w-full h-full absolute top-0 left-0">
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                {isProcessedVideo ? (
                  <FontAwesomeIcon
                    icon={faPlayCircle}
                    color="white"
                    className="hover:scale-105 cursor-pointer"
                    fontSize={24}
                  />
                ) : (
                  <div className="flex flex-col items-center cursor-not-allowed">
                    <CustomerLoader />
                    <p className="text-white">
                      {constants.VIDEO_INDEXING_MESSAGE}
                      {video?.processingProgress}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className="flex flex-col p-2">
        <div>
          <h5>{trimString(video?.name, 25)}</h5>
          <p className="font-xxs text-gray-600">
            <strong>Creator:</strong> {video?.userName}
          </p>
        </div>
        <div className="flex flex-row items-center justify-between">
          <ConfirmationModal
            title="Delete"
            handleSubmit={handleDeleteVideo}
            buttonIcon={faTrash}
            disabled={!isProcessedVideo}
            isDeleteProcessing={isDeleteProcessing}
          />
          <ChatModal
            buttonIcon={faComment}
            videoDetails={{ video, thumbnail }}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Video);
