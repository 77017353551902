// eslint-disable-next-line import/no-anonymous-default-export
export default {
  FAST_API_DEV_ENDPOINT: "https://gt40.augmentappdemo.com",
  FAST_API_PROD_ENDPOINT: "http://127.0.0.1:8000",
  ACCOUNT_ID: "ca080a13-1c5f-4104-b834-a3dbcbd8ed8c",
  API_BASE_URL: "https://api.videoindexer.ai",
  IMAGE_URL_PREFIX: "data:image/jpeg;base64,",
  NO_VIDEO_FOUND: "No videos found...Please upload to explore it",
  SOMETHING_WENT_WRONG: "Something went wrong",
  SUBSCRIPTION_KEY: "ac08a85b98fb479e928a46d2c1f00525",
  TOKEN_NAME: "vi_token",
  LOADING_MESSAGE: "Loading...Please wait",
  UPLOADING_MESSAGE: "Uploading...Please wait",
  SUCCESS_MESSAGE: {
    FILE_UPLOAD: "File uploaded successfully",
    VIDEO_DELETED: "Video deleted successfully",
  },
  ERROR_MESSAGE: {
    UNSUPPORTED_FILE_TYPE: "Please upload supported file types",
    FILE_LIMIT_EXIST: "File exceeds maximum allowed upload size of 1.5GB",
    UPLOAD_FAILED: "Upload failed",
    AUTHENTICATION_FAILED: "Authentication failed",
    VIDEO_TOKEN_AUTHENTICATION_FAILED: "Authentication failed",
    SUMMARY: "Fetching summary...",
  },
  VIDEO_FILE_FORMAT: "video",
  FILE_SIZE_LIMIT: 1500 * 1000 * 1000, // Max Limit: 500 MB
  VIDEO_PROCESSING_API_CALL: 60 * 1000, // 15 seconds
  VIDEO_PROCESSED: "processed",
  UPLOAD_VIDEO_STATUS: "uploaded",
  VIDEO_INDEXING_MESSAGE: "Indexing...",
  DELETE_PROMPT_DEFAULT_MESSAGE: "Are you sure you want to delete this item?",
  USER_PROFILE_NAME: "Amal",
  ROUTES: {
    LIBRARY: "/library",
  },
  VIDEO_PLAYER_ID: "videoPlayer-GT40",
  NO_CONTENT: {
    DESCRIPTION: "No description found",
    KEYWORDS: "No keywords found",
    LABELS: "No labels found",
  },
  EMOTION_OTHERS: "Others",
};
