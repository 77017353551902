import { useSelector } from "react-redux";
import React, { memo, useMemo } from "react";

import BrandDetails from "./BrandDetails";
import InsightSection from "../InsightSection";
import HorizontalTimeline from "../HorizontalTimeline";
import { getTimelineInfo } from "../../../../utils/helpers";
import { videosDetailsSelector } from "../../../../store/reducers/videoDetails/index.reducer";

const Brands = () => {
  const {
    defaultInsight: { currentBrand },
    insights: { brands },
  } = useSelector(videosDetailsSelector);

  //  const words = useMemo(() => {
  //    const words = brands?.map((brand) => ({
  //      text: brand?.name,
  //      value: brand.instances.length,
  //    }));
  //    return words;
  //  }, [brands]);

  const timeline = useMemo(
    () => currentBrand?.instances?.map((brand) => getTimelineInfo(brand)),
    [currentBrand?.instances]
  );

  if (!brands?.length) {
    return null;
  }

  return (
    <InsightSection title="brand counts" childClassName="pb-3 -mt-1 px-2 pt-0 flex-1">
      <div className="flex flex-col w-full gap-3">
        <div>
          <BrandDetails />
        </div>
        <HorizontalTimeline timeline={timeline} />
      </div>
    </InsightSection>
  );
};

export default memo(Brands);
