import React, { memo } from "react";

import constants from "../utils/constants";

const Loading = ({ message = constants.LOADING_MESSAGE }) => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <p>{message}</p>
    </div>
  );
};

export default memo(Loading);
