import React from "react";
import { Link } from "react-router-dom";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logo from "../../../assets/logo.png";
import constants from "../../../utils/constants";

const userProfileURL = "https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg";

const Header = () => {
  return (
    <div className="h-[50px] min-h-[50px] flex flex-row items-center justify-between bg-white px-5 py-2">
      <Link to={constants.ROUTES.LIBRARY}>
        <img src={logo} alt="logo-vid" height={50} width={100} />
      </Link>
      <div className="flex items-center gap-2 justify-center">
        <div className="w-8 h-8 rounded-3xl border border-gray-300 overflow-hidden relative">
          <img
            src={userProfileURL}
            alt="user profile"
            className="w-full h-full"
          />
        </div>
        <p className="text-sm">{constants.USER_PROFILE_NAME}</p>
        <FontAwesomeIcon icon={faCaretDown} color="gray" fontSize={10} />
      </div>
    </div>
  );
};

export default Header;
