import { toast } from "react-toastify";
import { MdContentCopy } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import constants from "../../../../../utils/constants";
import { generateSummaryForVideo } from "../../../../../api/helpers";
import CustomerLoader from "../../../../../components/CustomerLoader";
import {
  changeSummary,
  videosDetailsSelector,
} from "../../../../../store/reducers/videoDetails/index.reducer";
import { trimString } from "../../../../../utils/helpers";
import SummaryModal from "../../../../../components/SummaryModal";

const Summary = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState();
  const { videoDetails, summary } = useSelector(videosDetailsSelector);

  const handleCopyClipboard = async () => {
    if (!navigator.clipboard) {
      return toast.error("Clipboard API not available");
    }
    try {
      await navigator.clipboard.writeText(summary);
      toast.success("Copied");
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        if (videoDetails?.id) {
          const summary = await generateSummaryForVideo(videoDetails?.id);
          setIsLoading(false);
          dispatch(changeSummary(summary));
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    })();
  }, [dispatch, videoDetails?.id]);

  return (
    <div className="flex flex-col gap-2 p-2 pl-4">
      <div className="flex flex-row items-center gap-2 lg:justify-between">
        <h1 className="font-bold">Summary</h1>
        <button
          type="button"
          title="Copy"
          aria-label="copy to clipboard"
          onClick={handleCopyClipboard}
        >
          <MdContentCopy />
        </button>
      </div>
      <div className="w-full">
        {isLoading ? (
          <div className="flex justify-center w-full">
            <CustomerLoader />
          </div>
        ) : (
          <div className="text-sm font-normal">
            {summary ? (
              <span>
                {trimString(summary, 250, ".")}
                {summary.length > 250 ? (
                    <SummaryModal summary={ summary} />
                ) : null}
              </span>
            ) : (
              <span>{constants.ERROR_MESSAGE.SUMMARY}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
