import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StackBarChart = ({ data, title }) => {
  const dataSet2 = useMemo(() => {
    return data?.series?.map((value) => 100 - value);
  }, [data?.series]);
  return (
    <Bar
      options={{
        plugins: {
          title: {
            display: true,
            text: title,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            max: 100,
          },
        },
      }}
      data={{
        labels: data.labels,
        datasets: [
          {
            label: "Positive",
            data: data.series.map(el => el * 10),
            backgroundColor: "#9F7DBA",
          },
          {
            label: "Neutral",
            data: dataSet2,
            backgroundColor: "#7EB74F",
          },
        ],
      }}
    />
  );
};

export default StackBarChart;
