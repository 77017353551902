import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changePlace,
  videosDetailsSelector,
} from "../../../../store/reducers/videoDetails/index.reducer";

const PlaceDetails = () => {
  const {
    insights: { namedLocations },
    defaultInsight: { currentPlace },
  } = useSelector(videosDetailsSelector);
  const dispatch = useDispatch();

  const handleMoveToSpecificTime = (event) => {
    const newBrand = namedLocations?.find(
      (brand) => brand.name === event.target.value
    );
    dispatch(changePlace(newBrand));
  };
    
    const startWithCapital = (namedLocations) => {
      const result = namedLocations?.filter(
        (f) => f?.name?.charAt(0) === f?.name?.charAt(0)?.toUpperCase()
      );
      return result;
    };

  return (
    <>
      <div className="mt-4">
        <select
          className="px-2 py-1 border"
          value={currentPlace.name}
          onChange={handleMoveToSpecificTime}
        >
          {startWithCapital(namedLocations)?.map((brand) => (
            <option
              key={brand.id}
              value={brand.name}
            >
              {brand.name} ({brand.instances.length})
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default PlaceDetails;
