import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import React, { memo, useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import Loading from "./Loading";
import constants from "../utils/constants";
import { fileSupportTypes } from "../common/static";
import { postVideo, uploadVideo } from "../api/helpers";
import { fetchAllVideos } from "../store/reducers/videos/index.thunk";

const FileUploader = ({ buttonIcon, buttonText }) => {
  const [open, setOpen] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isValidFile = (file) => {
    if (!fileSupportTypes?.includes(file.type.split("/")[0].toLowerCase())) {
      toast.error(constants.ERROR_MESSAGE.UNSUPPORTED_FILE_TYPE);
      return false;
    }
    if (file.size > constants.FILE_SIZE_LIMIT) {
      toast.error(constants.ERROR_MESSAGE.FILE_LIMIT_EXIST);
      return false;
    }
    return true;
  };

  const handleFileUpload = useCallback(
    async (files) => {
      try {
        if (files) {
          const file = files[0];
          if (isValidFile(file)) {
            const formData = new FormData();
            formData.append("file", file);

            const { filename, blob_url } = await uploadVideo(formData);
            if (filename && blob_url) {
              const indexDetails = await postVideo(filename, blob_url);

              if (
                indexDetails?.state?.toLowerCase() ===
                constants.UPLOAD_VIDEO_STATUS
              ) {
                dispatch(fetchAllVideos());
                toast.success(constants.SUCCESS_MESSAGE.FILE_UPLOAD);
              } else {
                throw new Error(constants.ERROR_MESSAGE.UPLOAD_FAILED);
              }
            } else {
              throw new Error(constants.ERROR_MESSAGE.UPLOAD_FAILED);
            }
          }
        }
        handleClose();
        setIsFileUploading(false);
      } catch (error) {
        toast.error(error?.message);
        setIsFileUploading(false);
      }
    },
    [dispatch]
  );

  const handleFileChange = useCallback(
    async (event) => {
      try {
        setIsFileUploading(true);
        const { files } = event?.target;

        await handleFileUpload(files);
      } catch (error) {
        toast.error(error?.message);
        setIsFileUploading(false);
      }
    },
    [handleFileUpload]
  );

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        e.stopPropagation();

        setIsDragging(false);
        setIsFileUploading(true);
        const { files } = e.dataTransfer;

        await handleFileUpload(files);
        handleClose();
      } catch (error) {
        toast.error(error?.message);
        setIsFileUploading(false);
      }
    },
    [handleFileUpload]
  );

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  };

  return (
    <div>
      <button type="button" className="btn-primary" onClick={handleClickOpen}>
        {buttonIcon && <FontAwesomeIcon icon={buttonIcon} />}
        <span>{buttonText}</span>
      </button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Upload and Index</DialogTitle>
        <DialogContent>
          {isFileUploading ? (
            <Loading message={constants.UPLOADING_MESSAGE} />
          ) : (
            <div
              className={`relative flex flex-col items-center bg-gray-50 justify-center gap-3 pt-1 w-full h-48 ${
                isDragging ? "border-2 border-dashed" : ""
              }`}
              onDragOver={handleDragOver}
            >
              {!isDragging && (
                <>
                  <p>Drag and drop files</p>
                  <span className="text-sm text-slate-500">or</span>
                  <div className="cursor-pointer">
                    <label
                      htmlFor="video"
                      className="px-4 py-2 cursor-pointer w-full h-full bg-blue-500 text-white rounded"
                    >
                      <span className="text-sm">Browse files</span>
                      <input
                        type="file"
                        id="video"
                        accept="video/*"
                        required
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                </>
              )}
              {isDragging && (
                <p
                  className="absolute top-0 left-0 bg-gray-100 w-full h-full"
                  onDrop={handleDrop}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                  <span className="flex items-center justify-center w-full h-full">
                    Please drop your file here
                  </span>
                </p>
              )}
            </div>
          )}
        </DialogContent>
        <button type="button" onClick={handleClose}>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="absolute top-2 right-2 cursor-pointer"
            fontSize={24}
          />
        </button>
      </Dialog>
    </div>
  );
};

export default memo(FileUploader);
