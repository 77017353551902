import { useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import "./Map.css"
import Geocode from "react-geocode";
import { useEffect } from "react";

export default function Map({ filteredPlaces }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDqaHPrK4LyrJBVVSV18WSUt0Gj0ECA1v4",
  });
  const [latLong, setLatLong] = useState([])

  useEffect(() => {
    const locations = [];
    if (filteredPlaces?.length) {
      for (let place of filteredPlaces) {
        Geocode.setApiKey("AIzaSyDqaHPrK4LyrJBVVSV18WSUt0Gj0ECA1v4");
        Geocode.setLocationType("ROOFTOP");
        Geocode.fromAddress(place?.name).then(
          (response) => {
            if(response) {
              locations.push({
                name: place.name,
                position: response?.results[0]?.geometry?.location,
              });
            }
          },
          (error) => {
            console.error(error);
          }
        );
      }
      setLatLong(locations);
    }       
  }, [])
  
  if (!isLoaded) return <div>Loading...</div>;

  return filteredPlaces?.length && latLong.length ? (
    <div className="LocationMap">
      <GoogleMap
        zoom={2}
        center={latLong[0]?.position}
        mapContainerClassName="map-container"
      >
        {latLong?.map(el => {
          if (el!=="")
          return <MarkerF key={el.name} position={{ lat: el.position.lat, lng: el.position.lng }} label={el.name} />
        }
          )}
      </GoogleMap>
    </div>
  ) : <></>;
}

