import { useEffect, useCallback } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import routes from "./common/routes";
import constants from "./utils/constants";
import { getAuthToken } from "./api/helpers";
import ErrorPage from "./components/ErrorPage";
import Dashboard from "./pages/Dashboard/Dashboard";

function App() {
  /**
   * Initial loading for access token
   */
  const initializeApp = useCallback(async () => {
    try {
      if (!localStorage.getItem(constants.TOKEN_NAME)) {
        await getAuthToken();
      }
    } catch (error) {
      toast.error(constants.ERROR_MESSAGE.AUTHENTICATION_FAILED);
    }
  }, []);

  useEffect(() => {
    initializeApp();
  }, [initializeApp]);

  return (
    <div className="w-screen h-screen">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} errorElement={<ErrorPage />}>
            {routes.map(({ to, Element, sub }) => (
              <Route key={to} path={to} element={<Element />}>
                {sub
                  ? sub?.map(({ to: subTo, Element: SubElement }) => (
                      <Route
                        key={subTo}
                        path={subTo}
                        element={<SubElement />}
                      />
                    ))
                  : null}
              </Route>
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
