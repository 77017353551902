import Library from "../pages/Library";
import VideoDetails from "../pages/VideoDetails";
import ComingSoon from "../components/ComingSoon";
import Videos from "../pages/Library/components/Videos";

const routes = [
  {
    to: "/",
    Element: Library,
    sub: [
      { to: "/library/:id", Element: VideoDetails },
      { to: "/library", Element: Videos },
    ],
  },
  { to: "/integrations", Element: ComingSoon },
  { to: "/support", Element: ComingSoon },
  { to: "/settings", Element: ComingSoon },
  {
    to: "/dashboard",
    Element: ComingSoon,
  },
];

export default routes;
