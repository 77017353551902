import { configureStore } from "@reduxjs/toolkit";

import videosReducer from "./reducers/videos/index.reducer";
import videoDetailsReducer from "./reducers/videoDetails/index.reducer";


export default configureStore({
  reducer: {
    videos: videosReducer,
    videoDetails: videoDetailsReducer,
  },
});
