import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const backgroundColor = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
  "rgba(255, 99, 132, 0.5)",
  "rgba(54, 162, 235, 0.5)",
  "rgba(255, 206, 86, 0.5)",
  "rgba(75, 192, 192, 0.5)",
  "rgba(153, 102, 255, 0.5)",
  "rgba(255, 159, 64, 0.5)",
  "rgba(255, 99, 132, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(255, 159, 64, 0.2)",
];

const DoughnutChart = ({ data, label, title, width }) => {

  return (
    <div className="flex flex-row items-center justify-center">
      <div>
        {Array(data.labels.length)
          .fill(0)
          .map((value, index) => (
            <div key={index} className="flex flex-row items-center gap-4 font-bold text-gray-500">
              <p
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  backgroundColor: backgroundColor[index],
                }}
              ></p>
              <p className="text-sm">{data.labels[index]}</p>
              <p className="text-sm">{data.series[index]}</p>
            </div>
          ))}
      </div>
      <div style={{ width: width }} className="relative">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-2 flex flex-col items-center">
          <p className="font-bold text-xl text-gray-700">
            {data.labels.length}
          </p>
          <h3 className="font-bold text-base text-gray-400 uppercase">Total</h3>
        </div>
        <Doughnut
          options={{
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: true,
                text: title,
              },
            },
          }}
          data={{
            labels: data.labels,
            datasets: [
              {
                label: label,
                data: data.series,
                backgroundColor: backgroundColor,
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default DoughnutChart;
