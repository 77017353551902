import React from "react";
import { RxAvatar } from "react-icons/rx";

import chatLogo from "../../assets/chat.svg";

const Chat = ({ chat }) => {
  return (
    <div className={`flex flex-row gap-2 px-2 py-2 ${
      chat.role === "user" ? "bg-gray-200 rounded items-center" : "items-start"
    }`}>
      <div>
        {chat.role === "user" ? (
          <RxAvatar />
        ) : (
          null
          // <div className="w-5 h-5">
          // <img className="w-full h-full object-cover"
          //   src={chatLogo}
          //   alt="chat"
          // />
          // </div>
        )}
      </div>
      <span className="text-sm">
        {chat.content}
      </span>
    </div>
  );
};

export default Chat;
