import { useSelector } from "react-redux";
import React, { memo, useMemo } from "react";

import MentionDetails from "./MentionDetails"
import InsightSection from "../InsightSection";
import HorizontalTimeline from "../HorizontalTimeline";
import { getTimelineInfo } from "../../../../utils/helpers";
import VerticalChart from "../../../../components/VerticalChart";
import { videosDetailsSelector } from "../../../../store/reducers/videoDetails/index.reducer";

const Mentions = () => {
  const {
    defaultInsight: { currentMention },
    insights: { namedPeople },
  } = useSelector(videosDetailsSelector);

  const timeline = useMemo(
    () => currentMention?.instances?.map((topic) => getTimelineInfo(topic)),
    [currentMention?.instances]
  );

  const chartData = useMemo(() => {
    const labels = namedPeople?.map((person) => person?.name);
    const series = namedPeople?.map((person) => person?.instances?.length);

    return {
      labels,
      series,
    };
  }, [namedPeople]);

  if (!namedPeople?.length) {
    return null;
  }

  return (
    <InsightSection title="names" childClassName="pb-3 px-2 pt-0 -mt-4">
      <VerticalChart data={chartData} label="Names" />
      <div className="flex flex-col w-full gap-3">
        <MentionDetails />
      </div>
      <HorizontalTimeline timeline={timeline} />
    </InsightSection>
  );
};

export default memo(Mentions);
