import { useParams } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../components/Loading";
import VideoInfo from "./components/VideoInfo";
import { getVideoSrt } from "../../api/helpers";
import AnalysisLeft from "./components/AnalysisLeft";
import AnalysisRight from "./components/AnalysisRight";
import { fetchVideoDetails } from "../../store/reducers/videoDetails/index.thunk";
import { videosDetailsSelector } from "../../store/reducers/videoDetails/index.reducer";

const VideoDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const shouldRender = useRef(true);

  const { isLoading } = useSelector(videosDetailsSelector);

  useEffect(() => {
    (async () => {
      if (shouldRender.current) {
        dispatch(fetchVideoDetails({ id }));
        await getVideoSrt(id, "txt");
        shouldRender.current = false;
      }
    })();
  }, [dispatch, id]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className="w-full h-full flex flex-col gap-1 overflow-auto">
      <div className="flex-none">
        <VideoInfo />
      </div>
      <div className="flex flex-col lg:flex-row gap-2 w-full flex-1">
        <div className="flex-1 overflow-auto video-info">
          <AnalysisLeft />
        </div>
        <div className="flex-1 overflow-auto video-info">
          <AnalysisRight />
        </div>
      </div>
    </section>
  );
};

export default React.memo(VideoDetails);
