import constants from "../utils/constants";

export const fileSupportTypes = [constants.VIDEO_FILE_FORMAT];

export const emotionColors = {
  joy: { color: "#22c55e", textColor: "text-green-500" },
  anger: { color: "#ef4444", textColor: "text-red-500" },
  sad: { color: "#3b82f6", textColor: "text-blue-500" },
  fear: { color: "#f97316", textColor: "text-orange-500" },
};

export const filterDefaultOptions = [
  { title: "show all", size: 0 },
  { title: ">= 5", size: 5 },
  { title: ">= 10", size: 10 },
];

export const chartColors = ["red", "green", "blue"];
