import jwtDecode from "jwt-decode";

import constants from "./constants";

export const getVideoPlayerURL = (videoId, videoAccessToken, t = 0) => {
  return `https://www.videoindexer.ai/embed/player/${constants.ACCOUNT_ID}/${videoId}/?accessToken=${videoAccessToken}&autoplay=true`;
};

export const getVideoSpecificTime = (startTime, currentStartTime) => {
  const time = startTime ? startTime : 0.1; // for re-rendering, change value 0 to 1
  const updatedTime = currentStartTime === time ? time + 0.1 : time; // for re-rendering

  return updatedTime;
};

export const getTimeInSeconds = (time) => {
  if (time) {
    const timeline = time?.split(":");

    const seconds = +timeline[0] * 60 * 60 + +timeline[1] * 60 + +timeline[2];
    return seconds;
  } else {
    return 0;
  }
};

export const getSeenDuration = (instances, totalDuration) => {
  const duration = instances?.reduce((prev, cur) => {
    const start = getTimeInSeconds(cur?.start);
    const end = getTimeInSeconds(cur?.end);
    return prev + (end - start);
  }, 0);

  return (duration / totalDuration) * 100;
};

export const getTimelineInfo = (insight) => {
  const start = getTimeInSeconds(insight?.start);
  const end = getTimeInSeconds(insight?.end);
  const totalLength = end - start;

  return {
    start,
    width: totalLength < 1 ? totalLength + 2 : totalLength,
    period: `${insight?.start} - ${insight?.end}`,
  };
};

export const sortInsightByInstances = (insights, order = "desc") => {
  if (!insights?.length) return [];

  if (order === "desc") {
    return insights
      ?.filter((insight) => insight?.instances?.length)
      ?.sort((a, b) => b?.instances?.length - a?.instances?.length);
  } else if(order === 'asc') {
    return insights
      ?.filter((insight) => insight?.instances?.length)
      ?.sort((a, b) => a?.instances?.length - b?.instances?.length);
  }
};

export const trimString = (str, maxLength = 150, suffix = "...") => {
  if (str?.length <= maxLength || !str) return str;
  return str?.slice(0, maxLength).trim() + suffix;
};

export const isTokenExpired = (token) => {
  const decoded = jwtDecode(token);
  return decoded?.exp < new Date() / 1000;
};

export const filterInsightsByInstances = (insights, condition) => {
  return insights?.filter((insight) => {
    const len = insight?.instances?.length;
    return len >= condition;
  });
};

export const formatDate = (date) => {
  const event = new Date(date);
  const value = event.toString().split(" ");

  return [...value.slice(1, 2), value[2] + ",", value[3]].join(" ");
};

export const formatSecondsToHourAndMinute = (seconds) => {
  const d = Number(seconds);
  const hour = Math.floor(d / 3600);
  const minute = Math.floor((d % 3600) / 60);
  //  const second = Math.floor((d % 3600) % 60);

  const hDisplay = hour > 0 ? hour + (hour === 1 ? "hr" : "hrs") : "";
  const mDisplay = minute > 0 ? minute + (minute === 1 ? "min" : "mins") : "";
  //  const sDisplay = second > 0 ? second + (second === 1 ? "sec" : "secs") : "";

  return hDisplay + " " + mDisplay;
};
