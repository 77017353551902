import { Tooltip } from "@mui/material";
import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changeStartTime,
  videosDetailsSelector,
} from "../../../../store/reducers/videoDetails/index.reducer";
import { getVideoSpecificTime } from "../../../../utils/helpers";

const TimeFrame = ({ time }) => {
  const dispatch = useDispatch();
  const {
    currentStartTime,
    videoDetails: { durationInSeconds },
  } = useSelector(videosDetailsSelector);

  const width = useMemo(
    () => (time?.width / durationInSeconds) * 100,
    [durationInSeconds, time?.width]
  );

  const xStartingPoint = useMemo(
    () => (time?.start / durationInSeconds) * 100,
    [durationInSeconds, time?.start]
  );

  const handleMoveToSpecificTime = () => {
    const newTime = getVideoSpecificTime(time?.start, currentStartTime);
    dispatch(changeStartTime({ time: newTime }));
  };

  return (
    <Tooltip title={time?.period || ""} placement="top" arrow>
      <rect
        onClick={handleMoveToSpecificTime}
        width={`${width}%`}
        height="12"
        x={`${xStartingPoint}%`}
        y="5"
        className="transition item cursor-pointer"
      ></rect>
    </Tooltip>
  );
};

export default memo(TimeFrame);
